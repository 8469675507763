.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container th {
  font-weight: bold;
}

.select {
  padding: 0.5em;
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  padding-right: 1.25em;
}

.header > * {
  width: 45%;
  margin: 0.5em;
}

.nthCol {
  font-weight: bold;
  width: 3em;
}
