.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.container th {
  font-weight: bold;
}

.select {
  padding: 0.5em;
}

.topRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.topRow > * {
  flex-grow: 1;
  width: auto;
}

.statsTitle {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.statsTitle > * {
  width: 100%;
  padding: 0 1em;
  box-sizing: border-box;
}

.placeholderContainer {
  height: 100%;
  width: 100%;
  padding: 0.5em;
}
