.wrapper {
  position: relative;
  height: 100%;
}

.fullscreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.button {
  position: absolute;
  top: 0px;
  right: 0px;
}
