.container {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: 14% 40% 10% 36%;
  overflow: hidden;
}

.container > * {
  padding: 0.5em;
  box-sizing: border-box;
}

.newAscentLogo {
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.newAscentLogo img {
  max-width: 100%;
  margin: auto;
  height: 5em;
}

.missionSubspaceLogo {
  grid-column: 2 / span 2;
  grid-row: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.missionSubspaceLogo img {
  max-width: 100%;
  height: 5em;
  margin: auto;
}

.missionStatusPane {
  grid-column: 4;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.missionStatus {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 1em;
}

.missionStatus > * {
  margin-right: 1em;
}

.missionStatus > *:last-child {
  margin-right: 0;
}

.loginPane {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / span 4;
  grid-row: 1 / span 4;
}

.loginForm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 200px;
  background-color: rgba(216, 216, 216, 0.8);
}

.livestreamPane {
  grid-column: 1;
  grid-row: 2 / span 3;
}

.chatPane {
  grid-column: 1;
  grid-row: 3 / span 2;
}

.chatPane iframe {
  height: 100%;
  width: 100%;
  border: 0;
}

.cesiumPane {
  grid-column: 2 / span 2;
  grid-row: 2 / span 2;
}

.cesiumPaneStudent {
  grid-column: 2 / span 2;
  grid-row: 2 / span 3;
}

.leaderboardPane {
  grid-column: 3;
  grid-row: 4;
}

.leaderboardPaneStudent {
  grid-column: 1;
  grid-row: 3 / span 2;
}

.statsPane {
  grid-column: 2;
  grid-row: 4;
}

.statsPaneStudent {
  grid-column: 1;
  grid-row: 2;
}

.chartPaneBottomRight {
  grid-column: 4;
  grid-row: 3 / span 2;
}

.chartPaneTopRight {
  grid-column: 4;
  grid-row: 2;
}
