.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.body {
  width: 900px;
  padding: 16px;
}

.center {
  align-items: center;
  align-content: flex-start;
}

.loginForm {
  margin: auto;
  max-width: 500px;
  display: flex;
  justify-content: center;
}

.livestreamPane {
  height: 400px;
  width: 100%;
}