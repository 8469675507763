.App {
  text-align: center;
  height: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  background-image: url('images/star-background.jpg');
  background-size: cover;
}

.content {
  flex-grow: 1;
}
