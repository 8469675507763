.container {
  border-radius: 5px;
  border: 1px solid darkgray;
  background-color: lightgray;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.header {
  padding: 0 1em;
  box-sizing: border-box;
  width: 100%;
  height: 3em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 3em;
}

.formControl {
  width: 45%;
  margin-top: 1em;
}

.select {
  padding: 0.5em;
}

.chartContainer {
  position: relative;
  width: 100%;
  min-height: 80%;
}

.chart {
  width: 100%;
}
